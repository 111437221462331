<template>
  <div v-if="checkPermission('list', 'action', 'credit-refund-reports')">
    <topic-component :topic="$t('SideBarItems.reports.list.returnCreditReport')"></topic-component>
    <b-card>
      <b-row>
        <b-col lg="4" md="6" sm="12">
          <b-form-group label-cols="12"
                        :label="$t('common.dateTime')" label-for="input-sm">
            <date-time-range-component v-model="time"/>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="3" sm="4">
          <select-filter-component :label-select="$t('report.returnCreditList.activity')"
                                   :options="report_refund_type"
                                   block_first_template
                                   v-model="form.date_type"/>
        </b-col>
        <b-col lg="4" md="3" sm="4">
          <select-filter-component :label-select="$t('report.returnCreditList.creditRefundStatus')"
                                   :options="refundStatusList"
                                   v-model="form.credit_refund_status"/>
        </b-col>
        <b-col lg="4" md="3" sm="4">
          <b-form-group label-cols="12"
                        :label="$t('report.campaignList.campaignId')" label-for="input-sm">
            <b-form-input v-model="form.campaign_id"/>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="3" sm="4">
          <b-form-group label-cols="12"
                        :label="$t('report.campaignList.campaignName')" label-for="input-sm">
            <b-form-input v-model="form.campaign_name"/>
          </b-form-group>
        </b-col>
        <b-col style="margin-top: 35px">
          <button type="button" class="btn waves-effect waves-light btn-gradient" @click="findReturnCredit">
            {{ $t('common.Search') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{$t('common.perPage')}}</span>
            <div>
              <b-form-select
                  v-model="form.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="findReturnCredit"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-table
            responsive
            class="mb-0"
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable }}">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>
          <template #cell(contacts)="data">
            {{ changeNumberFormat(data.item.contacts) }}
          </template>
          <template #cell(campaign_type)="data">
            <b-badge pill
                     :class="`px-2 text-white badge ${data.item.campaign_type === 'normal' ? 'bg-info' :'bg-primary' }`">
              {{ filterValue(typeList, data.item.campaign_type) }}
            </b-badge>
          </template>
          <template #cell(credit_refund_status)="data">
            {{ filterValue(refundStatusList, parseInt(data.item.credit_refund_status)) || '-' }}
          </template>
          <template #cell(total_credit_return)="data">
            <span class="text-bold text-blue">{{ changeNumberFormat(data.item.total_credit_return) }}</span>
          </template>
          <template #cell(allow_refund_start)="data">
            <span v-if="data.item.allow_refund_start && data.item.allow_refund_end">{{ changeDateFormat(data.item.allow_refund_start) + " - " + changeDateFormat(data.item.allow_refund_end) }}</span>
            <span v-else>-</span>
          </template>
          <template #cell(refunded_at)="data">
            <span>{{ changeDateFormat(data.item.refunded_at) }}</span>
          </template>
          <template #cell(action)="data">
            <i style="color: #0d73d9" class="action-button icon-plus mr-2"></i>
            <i style="color: #7367f0" class="action-button icon-note mr-2"></i>
            <i style="color: #28c76f" class="action-button icon-docs mr-2 "></i>
            <i style="color: #DF3F1E" class="action-button icon-trash "></i>
          </template>
        </b-table>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="totalRows"
              :per-page="form.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="findReturnCredit"
          ></b-pagination>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent";
import TopicComponent from "@/components/common/topicComponent";
import reportApi from "@/repository/reportApi";
import moment from "moment";
import alert from "@/common/alert";
import functionsCommon from "@/common/functions";
import functions from "@/common/functions";
import masterData from "@/common/masterData.json";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "returnCredit",
  components: {TotalListComponent, EmptyTable, SelectFilterComponent, TopicComponent, DateTimeRangeComponent},
  data: () => ({
    totalAll: 0,
    form: {
      date_from: null,
      date_to: null,
      credit_refund_status: null,
      date_type: "created_at",
      campaign_name: '',
      campaign_id: '',
      $skip: 1,
      $limit: 10,
    },
    isBusy: false,
    typeList: masterData.campaignTypeList,
    refundStatusList: masterData.refundStatusList,
    report_refund_type: masterData.reportRefundType,
    time: [
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
    ],
    fields: [
      {
        key: "createdAt",
        label: "report.returnCreditList.date",
      },
      {
        key: "campaign_id",
        label: "report.returnCreditList.campaignId",
      },
      {
        key: "campaign_name",
        label: "report.returnCreditList.campaignName",
      },
      {
        key: "contacts",
        label: "report.returnCreditList.contact",
      },
      {
        key: "campaign_type",
        label: "report.returnCreditList.campaignType",
      },
      {
        key: "credit_refund_status",
        label: "report.returnCreditList.creditRefundStatus",
      },
      {
        key: "total_credit_return",
        label: "report.returnCreditList.totalCreditReturn",
      },
      {
        key: "allow_refund_start",
        label: "report.returnCreditList.allowRefundAt",
      },
      {
        key: "refunded_at",
        label: "report.returnCreditList.refundedAt",
      }

    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
  }),
  mounted() {
    this.findReturnCredit()
  },
  methods: {
    resetSearch() {
      this.time = [
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
      ]
      this.form = {
        date_from: null,
        date_to: null,
        sender_id: null,
        sms_type: null,
        credit_refund_status: null,
        date_type: "created_at",
        receive_number: '',
        $skip: 1,
        $limit: 10,
      }
      this.findReturnCredit()
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    changeNumberFormat(date) {
      return date ? functionsCommon.changeNumberFormat(date) : '-'
    },
    findReturnCredit() {
      this.isBusy = !this.isBusy
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      reportApi.getReturnCreditAll(_.cloneDeep(this.form)).then(response => {
        if (response?.codeSYS === '001') {
          this.totalAll = response.total
          this.totalRows = response.total
          this.items = response.data
        } else {
          alert.findFailed(this, response.message)
        }
      }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  }
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}
</style>
