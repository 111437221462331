<template>
  <date-picker
      class="test-some"
      :value="value"
      range
      :type="type"
      @input="updateValue"
      :lang="lang"
      :disabled-date="disabledRangeFunc"
  />
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "dateTimeRangeComponent",
  components: {DatePicker},
  props: {
    value: {
      type: Array,
      request: false,
    },
    type: {
      type: String,
      request: false,
      default: 'datetime'
    },
    disabledRange: {
      type: Array,
      request: false,
      default: () => []
    },
  },
  computed: {},
  data() {
    return {
      lang: {
        formatLocale: {
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        },
        monthBeforeYear: false,
      },
    }
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e)
    },
    disabledRangeFunc: function (date) {
      if (this.disabledRange.length > 0) {
        return date < new Date(this.disabledRange[0]) || date > new Date(this.disabledRange[1]);
      }
    }
  },
}
</script>

<style lang="scss">
.mx-input {
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: #586A8F !important;
  border-radius: 8px !important;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: normal;
  font-size: 0.875rem;
}

.mx-datepicker-range {
  width: 100%;
  color: #586A8F !important;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: normal;
  font-size: 0.875rem;
}

.mx-calendar-content .cell.active {
  background-color: var(--primary-color);
  font-weight: 500;
}

.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  background-color: rgba(var(--primary-color-rgb), 0.2);
  color: #000;
  opacity: 0.6;
  filter: brightness(130%);
}

.mx-table-date .cell.not-current-month {
  background: none;
  color: #ccc;
}

.mx-time-column .mx-time-item.active {
  color: var(--primary-color);
  filter: brightness(80%);
}

.mx-calendar-content .cell:hover {
  background-color: var(--primary-color);
  filter: brightness(1.1);
  opacity: 1;
}
</style>